<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="name" label="Nama">
            <a-input v-model:value="formState.name" placeholder="Masukkan Nama" />
          </a-form-item>
          <a-form-item name="description" label="Deskripsi">
            <a-input v-model:value="formState.description" placeholder="Masukkan Deskripsi" />
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/master/laporan-manajemen">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const LaporanManajemenForm = {
  name: 'LaporanManajemenForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    const formState = reactive({
      name: '',
      description: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-manajemen',
          data: formState,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'laporan-manajemen',
          data: formState,
        });
      }
    };

    const rules = {
      name: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-manajemen',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      filterSelect,
    };
  },
};

export default LaporanManajemenForm;
</script>
