<template>
  <sdPageHeader :title="pageData.title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="month_year" label="Bulan">
            <a-month-picker
              v-model:value="formState.month_year"
              placeholder="Pilih Bulan"
              format="MM-YYYY"
              valueFormat="YYYY-MM"
              :disabled="mode == 'Ubah' ? true : false"
            />
          </a-form-item>
          <template v-for="variable in variables.data" :key="variable.id">
            <a-form-item :name="'variables[' + variable.id + ']'" :label="variable.name">
              <a-input
                v-if="variable.jenis_variable_laporan_manajemen.name == 'String'"
                v-model:value="formState.variables[variable.id]"
                :placeholder="'Masukkan ' + variable.name"
              />
              <a-input-number
                v-if="variable.jenis_variable_laporan_manajemen.name == 'Nominal'"
                v-model:value="formState.variables[variable.id]"
                :placeholder="'Masukkan ' + variable.name"
              />
              <a-date-picker
                v-if="variable.jenis_variable_laporan_manajemen.name == 'Tanggal'"
                v-model:value="formState.variables[variable.id]"
                :placeholder="'Masukkan ' + variable.name"
              />
            </a-form-item>
          </template>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link :to="'/laporan-manajemen/laporan-manajemen/' + $route.params.id">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const LaporanManajemenForm = {
  name: 'LaporanManajemenForm',
  components: { HorizontalFormStyleWrap, Main },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;

    const pageData = reactive({
      title: ' ',
    });

    const formState = reactive({
      month_year: dateObj.getUTCFullYear() + '-' + ('0' + month).slice(-2),
      variables: [],
    });

    const variables = reactive({
      data: {},
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      let data = toRaw(formState);
      let my = data.month_year.split('-');
      data['month'] = my[1];
      data['year'] = my[0];

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-laporan-manajemen',
          data: data,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.row_id,
          url: 'laporan-laporan-manajemen',
          data: data,
        });
      }
    };

    const rules = {
      month_year: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    onMounted(() => {
      formState.laporan_manajemen_id = parseInt(params.id);
      dispatch('axiosCrudGetData', 'laporan-manajemen/' + parseInt(params.id)).then(() => {
        pageData.title = props.mode + ' ' + crud.value.data.name;
      });

      dispatch('axiosCrudGetData', 'variable-laporan-manajemens-by-laporan-manajemen/' + parseInt(params.id)).then(
        () => {
          variables.data = crud.value;
        },
      );

      if (props.mode == 'Ubah') {
        formState.month_year = parseInt(params.year) + '-' + parseInt(params.month);

        dispatch('axiosCrudGetData', 'laporan-laporan-manajemen/' + params.row_id + '/edit').then(() => {
          crud.value.data.forEach(data => {
            formState.variables[data.variable_laporan_manajemen_id] = data.nilai_item;
          });
          console.log('debug', formState.variables.data);
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      variables,
      pageData,
    };
  },
};

export default LaporanManajemenForm;
</script>
